
import DropdownOption from '@/store/models/DropdownOption';
import { EmailAlertsClass } from '@/utils/classes/EmailAlertsClass';
import {
  convertPeriodScheduleFromMins,
  fixedDropdownValues
} from '@/utils/EmailAlertsUtils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import FormDropdownInput from '../common/FormDropdownInput.vue';
@Component({
  components: {
    FormDropdownInput
  },
  methods: {
    convertPeriodScheduleFromMins
  }
})
export default class EmailFrequency extends Vue {
  @Prop() emailAlerts!: EmailAlertsClass;
  @Prop() width!: number;

  public fixedDropdownValues = fixedDropdownValues;

  public updatePeriodSchedule(value: DropdownOption) {
    this.emailAlerts.tempPreferences.periodSchedule = +value.value;
  }
}
