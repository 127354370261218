
import { CustomFieldObjectPreferences } from '@/services/api/models/Preferences';
import CustomField from '@/store/models/CustomField';
import CustomStore from '@/store/modules/CustomStore';
import { getEntityApiCamelCaseWord } from '@/utils/AppName';
import { mixins } from 'vue-class-component';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component({ methods: { getEntityApiCamelCaseWord } })
export default class AdditionalCustomFieldsModal extends mixins(AppName) {
  @Prop() propSelectedCustomFields!: CustomFieldObjectPreferences;
  public selectedCustomFields: CustomFieldObjectPreferences = {
    products: [],
    tanks: [],
    vats: [],
    feed_tanks: []
  };
  get customFields() {
    return CustomStore.customFields;
  }

  get customEntityFields() {
    return CustomStore.customEntityFields;
  }

  mounted() {
    if (this.propSelectedCustomFields) {
      this.setSelectedFields();
    }
  }

  @Watch('propSelectedCustomFields', { deep: true })
  customFieldsLoaded() {
    this.setSelectedFields();
  }

  public setSelectedFields() {
    this.selectedCustomFields = JSON.parse(
      JSON.stringify(this.propSelectedCustomFields)
    );
  }

  public isSelected(
    field: CustomField,
    level: 'products' | 'tanks' | 'vats' | 'feed_tanks'
  ) {
    const levelFields = this.selectedCustomFields[level];
    return levelFields
      ?.map(objectField => objectField.name)
      .includes(field.name);
  }

  public addField(
    field: CustomField,
    level: 'products' | 'tanks' | 'vats' | 'feed_tanks'
  ) {
    const levelFields = this.selectedCustomFields[level];
    if (this.isSelected(field, level)) {
      this.selectedCustomFields[level] =
        levelFields?.filter(
          selectedField => selectedField.name != field.name
        ) ?? [];
    } else {
      levelFields?.push(field);
    }
  }

  public confirm() {
    this.$emit('confirm', this.selectedCustomFields);
    this.closeModal();
  }

  public closeModal() {
    this.$root.$emit('bv::hide::modal', 'additionalCustomFields', '#btnHide');
  }
}
