
import { storeState } from '@/store/Globals';
import { AppFilters, SavedAppFilters } from '@/store/models/Filters';
import { EmailAlertsClass } from '@/utils/classes/EmailAlertsClass';
import { alterCustomFiltersFromSaved } from '@/utils/EmailAlertsUtils';
import { isFiltersDefault } from '@/utils/FiltersUtils';
import { getAppFilter } from '@/utils/GenericUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import FeedFiltersModal from '../modal/FeedFiltersModal.vue';
import FuelFiltersModal from '../modal/FuelFiltersModal.vue';
import MilkFiltersModal from '../modal/MilkFiltersModal.vue';

@Component({
  components: {
    MilkFiltersModal,
    FuelFiltersModal,
    FeedFiltersModal
  }
})
export default class FilterSites extends mixins(AppName, ConstantsMixin) {
  @Prop() emailAlerts!: EmailAlertsClass;
  @Prop() width!: number;
  public hasSavedFilters = false;

  mounted() {
    const loadedFilters = getAppFilter().filters as AppFilters;
    this.hasSavedFilters = !isFiltersDefault(loadedFilters);
  }

  get storeLoadingState() {
    return storeState.loading;
  }

  public applyCustomFilters(saveFilters: SavedAppFilters, filters: AppFilters) {
    this.emailAlerts.tempPreferences.customFilter = filters;
    this.emailAlerts.generateListFilters(filters);
  }

  public async loadDefaultFilters() {
    const loadedFilters = getAppFilter().filters as AppFilters;
    if (!isFiltersDefault(loadedFilters)) {
      let alteredFilters = { ...loadedFilters };
      alteredFilters = await alterCustomFiltersFromSaved(alteredFilters);
      this.emailAlerts.generateListFilters(alteredFilters);
    } else {
      this.hasSavedFilters = false;
      this.emailAlerts.listFilters = [];
    }
  }

  public openFiltersModal() {
    this.$root.$emit(
      'bv::show::modal',
      `${this.getAppName(false)}FiltersModal`,
      '#btnShow'
    );
  }
}
