
import AdditionalCustomFieldsModal from '@/components/modal/AdditionalCustomFieldsModal.vue';
import { CustomFieldObjectPreferences } from '@/services/api/models/Preferences';
import { mixins } from 'vue-class-component';
import { Component, Model, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component({
  components: {
    AdditionalCustomFieldsModal
  }
})
export default class VisibleCustomFields extends mixins(AppName) {
  @Prop() width!: number;
  @Model('additionalFieldsSelected')
  readonly selectedCustomFields!: CustomFieldObjectPreferences;
  public addCustomFields = false;

  mounted() {
    this.loadCustomFieldToggle();
  }

  @Watch('selectedCustomFields', { deep: true })
  customFieldsLoaded() {
    this.loadCustomFieldToggle();
  }

  public hasCustomFields() {
    return (
      this.selectedCustomFields.products.length != 0 ||
      this.selectedCustomFields.tanks?.length != 0 ||
      this.selectedCustomFields.vats?.length != 0 ||
      this.selectedCustomFields.feed_tanks?.length != 0
    );
  }

  public loadCustomFieldToggle() {
    if (this.hasCustomFields()) {
      this.addCustomFields = true;
    }
  }

  public openVisibleCustomFieldsModal() {
    this.$root.$emit('bv::show::modal', 'additionalCustomFields', '#btnShow');
  }

  public setCustomFieldToggle(value: boolean) {
    this.addCustomFields = value;
    if (!value) {
      this.$emit('additionalFieldsSelected', {
        products: [],
        tanks: [],
        vats: [],
        feed_tanks: []
      });
    }
  }

  public customFieldSelectionUpdated(
    updatedCustomFields: CustomFieldObjectPreferences
  ) {
    this.$emit('additionalFieldsSelected', updatedCustomFields);
  }
}
