var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.width < 576)?_c('hr',{staticClass:"px-4"}):_vm._e(),_c('div',{staticClass:"text-mediumBlue",class:_vm.width >= 576 ? 'mt-60 ml-30' : 'mt-30'},[(_vm.width >= 576)?_c('h5',{staticClass:"font-weight-bolder"},[_vm._v(" Email Frequency ")]):_c('strong',{staticClass:"text-mediumBlue my-auto font-weight-bold"},[_vm._v(" Email Frequency ")]),_c('BRow',{class:_vm.width < 576 ? 'mt-30' : ''},[_c('BCol',{class:_vm.width >= 576 ? 'mt-30 ml-30' : 'my-auto'},[_c('BRow',{class:_vm.width >= 576 ? 'mt-30' : 'my-auto'},[_c('BCol',{staticClass:"col40 my-auto"},[_c('BFormRadio',{staticClass:"my-auto pointerOnHover",attrs:{"name":"email-frequency","value":"preset","size":"lg"},model:{value:(_vm.emailAlerts.emailFrequency),callback:function ($$v) {_vm.$set(_vm.emailAlerts, "emailFrequency", $$v)},expression:"emailAlerts.emailFrequency"}})],1),_c('BCol',{staticClass:"my-auto pointerOnHover",on:{"click":function($event){_vm.emailAlerts.emailFrequency = 'preset'}}},[_c('strong',{staticClass:"my-auto"},[_vm._v("Preset Times")])])],1)],1),_c('BCol',{class:_vm.width >= 576 ? 'mt-30 ml-30' : 'my-auto'},[_c('BRow',{class:_vm.width >= 576 ? 'mt-30' : 'my-auto'},[_c('BCol',{staticClass:"col40 my-auto"},[_c('BFormRadio',{staticClass:"my-auto",attrs:{"name":"email-frequency","value":"recur","size":"lg"},model:{value:(_vm.emailAlerts.emailFrequency),callback:function ($$v) {_vm.$set(_vm.emailAlerts, "emailFrequency", $$v)},expression:"emailAlerts.emailFrequency"}})],1),_c('BCol',{staticClass:"my-auto pointerOnHover",on:{"click":function($event){_vm.emailAlerts.emailFrequency = 'recur'}}},[_c('strong',{staticClass:"my-auto"},[_vm._v("Recurring Intervals")])])],1)],1)],1),_c('BRow',{staticClass:"mt-0"},[(
          (_vm.emailAlerts.emailFrequency == 'preset' && _vm.width < 576) ||
            _vm.width >= 576
        )?_c('BCol',{class:_vm.width >= 576 ? 'ml-30' : ''},[_c('div',{class:_vm.width >= 576 ? 'mt-30 ml-30' : 'mt-30 mx-30',style:(_vm.emailAlerts.emailFrequency != 'preset' ? 'opacity: 50%' : '')},_vm._l(([0, 1, 2, 3, 4]),function(value){return _c('div',{key:value},[_c('BRow',{staticClass:"mt-0"},[_c('BCol',{staticClass:"col40 my-auto"},[_c('BFormCheckbox',{staticClass:"my-auto",class:_vm.emailAlerts.emailFrequency == 'preset'
                      ? 'pointerOnHover'
                      : '',attrs:{"id":`fixedChecked${value}`,"value":value,"size":"lg","disabled":_vm.emailAlerts.emailFrequency != 'preset'},model:{value:(_vm.emailAlerts.fixedChecked),callback:function ($$v) {_vm.$set(_vm.emailAlerts, "fixedChecked", $$v)},expression:"emailAlerts.fixedChecked"}})],1),_c('BCol',[_c('FormDropdownInput',{attrs:{"inputAttrs":{
                    id: `preset-dropdown-${value}`,
                    options: _vm.fixedDropdownValues,
                    variant: 'mediumBlue',
                    value: _vm.emailAlerts.tempPreferences.fixedSchedule[value],
                    disabled: _vm.emailAlerts.emailFrequency != 'preset'
                  },"id":"`preset-dropdown-${value}`"},model:{value:(_vm.emailAlerts.tempPreferences.fixedSchedule[value]),callback:function ($$v) {_vm.$set(_vm.emailAlerts.tempPreferences.fixedSchedule, value, $$v)},expression:"emailAlerts.tempPreferences.fixedSchedule[value]"}})],1)],1)],1)}),0)]):_vm._e(),(
          (_vm.emailAlerts.emailFrequency == 'recur' && _vm.width < 576) ||
            _vm.width >= 576
        )?_c('BCol',{class:_vm.width >= 576 ? 'ml-30' : ''},[_c('div',{class:_vm.width >= 576 ? 'mt-30 ml-30' : 'mt-30 mx-30',style:(_vm.emailAlerts.emailFrequency != 'recur' ? 'opacity: 50%' : '')},[_c('FormDropdownInput',{attrs:{"inputAttrs":{
              id: 'recur-dropdown',
              label: 'Send email every',
              options: [
                { value: 30, label: '30 mins' },
                { value: 60, label: '1 hr' },
                { value: 120, label: '2 hr' },
                { value: 180, label: '3 hr' },
                { value: 240, label: '4 hr' },
                { value: 300, label: '5 hr' },
                { value: 360, label: '6 hr' }
              ],
              variant: 'mediumBlue',
              value: {
                value: _vm.emailAlerts.tempPreferences.periodSchedule,
                label: _vm.convertPeriodScheduleFromMins(
                  _vm.emailAlerts.tempPreferences.periodSchedule
                )
              },
              disabled: _vm.emailAlerts.emailFrequency != 'recur'
            },"id":"recur-dropdown"},on:{"input":_vm.updatePeriodSchedule}})],1)]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }