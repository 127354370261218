
import FormTextInput from '@/components/common/FormTextInput.vue';
import EmailDeliveryOptions from '@/components/email-alerts/EmailDeliveryOptions.vue';
import EmailFrequency from '@/components/email-alerts/EmailFrequency.vue';
import FilterSites from '@/components/email-alerts/FilterSites.vue';
import VisibleCustomFields from '@/components/email-alerts/VisibleCustomFields.vue';
import AppName from '@/components/mixin/AppName.vue';
import ConstantsMixin from '@/components/mixin/Constants.vue';
import { trackEvent } from '@/services/Mixpanel';
import { milkGlobals, storeState } from '@/store/Globals';
import FuelType from '@/store/models/FuelType';
import User from '@/store/modules/User';
import { EmailAlertsClass } from '@/utils/classes/EmailAlertsClass';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  components: {
    FormTextInput,
    VisibleCustomFields,
    EmailFrequency,
    EmailDeliveryOptions,
    FilterSites
  },
  computed: {
    ...mapGetters('user', {
      currentUser: 'currentUser',
      token: 'token'
    }),
    getHasNonFonterra() {
      return milkGlobals.hasNonFonterra;
    }
  }
})
export default class EmailAlerts extends mixins(AppName, ConstantsMixin) {
  public width = 1000;

  public saveButton = 'Save';
  public fuelTypes: FuelType[] = [];
  public emailAlerts = new EmailAlertsClass();

  public newTankVisible = true;
  public lowLevelVisible = true;

  get hasHistory() {
    return window.history.length > 2;
  }

  get renderNewTanks() {
    return this.$route?.meta?.productType === 'fuel';
  }

  get isMilk() {
    return this.$route?.meta?.productType === 'milk';
  }

  mounted() {
    trackEvent('User viewing email alerts page');
    this.updateWidth();
  }

  created() {
    window.addEventListener('resize', this.updateWidth);
  }

  destroyed() {
    window.removeEventListener('resize', this.updateWidth);
  }

  @Watch('emailAlerts', { deep: true })
  settingsChanged() {
    this.saveButton = 'Save';
  }

  @Watch('storeLoadingState')
  storeStateUpdated() {
    if (!this.storeLoadingState) {
      this.emailAlerts.setCustomFieldsFromSaved();
      this.emailAlerts.generateListFilters(
        this.emailAlerts.tempPreferences.customFilter
      );
    }
  }

  get storeLoadingState() {
    return storeState.loading;
  }

  public async save() {
    this.saveButton = 'Saving';
    const didErrorOccur = await this.emailAlerts.savePreferences();
    if (didErrorOccur) {
      this.saveButton = 'Save';
    } else {
      setTimeout(() => {
        this.saveButton = 'Saved';
      }, 1000);
      User.fetchCurrentUser();
    }
    this.$bvToast.toast(
      didErrorOccur
        ? 'An error occured when saving'
        : 'Emails settings updated',
      {
        title: didErrorOccur ? 'Error' : 'Settings updated',
        toaster: 'b-toaster-bottom-center',
        solid: true,
        append: false
      }
    );
  }

  public updateWidth() {
    this.width = window.innerWidth;
  }
}
